.module {
    display: flex;
    flex-direction: column;
    align-items: center; 
    height: 100vh; 
    overflow-y: auto;
    padding: 20px;

}
.top_content {
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0px 20px;
}

.progress-bar-wrapper {
    position: absolute; 
    left: 50%; 
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%; 
}

.module-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto; 
}

.module-title {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: right;
}

.date-display {
    font-size: small;
    margin-top: -17px;
    text-align: right;
}

.timer-container {
    display: flex;
    align-items: center;
    text-align: right;
    cursor: pointer;
}
.timer-icon {
    font-size: 1.5rem;
    text-align: right;
}
.timer-container i {
    font-size: 2rem; 
    transition: color 0.3s ease, transform 0.3s ease; 
}

.timer-container i.paused {
    color: grey; 
}

.timer-container i:hover {
    transform: scale(1.1);
}


.main_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    align-items: flex-start; 
    width: 85%;
    max-width: 1200px;
    margin-top: 0px;  
}

.card {
    width: 100%;
    max-height: 650px;
    margin:0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}

.card-body {
    max-height: 650px;
    flex: 1;
    flex-wrap: wrap; 
    overflow:hidden;
    overflow-y: auto; 
    padding: 20px; 

   
}

.left-content {
    flex: 1; 
    min-width: 0;
    margin-right: 20px;
    overflow:hidden; 
    overflow-y: auto;
    padding: 10px;
    max-height: 100%;
    box-sizing: border-box;

}

.passage-container {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #fafafa;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
    max-height: 100%; 
    box-sizing: border-box;
    overflow: hidden;
    
}
.passage  {
    text-align: justify;
    word-break: break-word;
    word-wrap: break-word;  
    hyphens: none; 
}
.passage p {
    font-size: 16px; 
    color: #333; 
    margin: 0;
  
 
    display: inline;
    
}

.content-divider{
    border-left: 2px solid #ccc; 
    max-height: 530px;
    margin-top:15px;
    padding-top:150px;
    padding-bottom:20%;
    margin-right:20px;

}

.right-content {
    flex: 2; 
    min-width: 0;
    overflow:hidden;
    overflow-y: auto;
    text-align: left;
    padding-top:10px;
    padding-bottom:5%;
    max-height:100%;
   
}
.right-content .card-title{
    padding-bottom:20px;
}



.card-body .content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%; 
    flex-wrap: nowrap;
   
    max-height: 600px;
}


.option-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem; 
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%; 
    flex-wrap: nowrap; 
}


.btn btn-option {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border-radius: 50%;
    width: 100%;
    cursor: pointer;
    margin: 10px;
    font-size: 20px;
    background-color: #f0f0f0;
    color: #000;
    
 

    transition: background-color 0.2s ease;
}
.btn-option {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border-radius: 20px;
    width: 100%; 
    cursor: pointer;
    margin-bottom: 30px ;
    font-size: 1rem;
    background-color: #f0f0f0;
    color: #000;
    transition: background-color 0.2s ease;
    
}

.btn-selected {
   
    color: #fff;
}
.btn-selected .option-label {
    color: #61dafb; 
    border-color: #61dafb; 
}



.option-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #000;
    font-size: 18px;
    background-color: #f0f0f0;
    margin-right: 10px;
   
    height: 40px; 
    flex-shrink: 0; 
    width: 30px; 
    width: 40px;
    font-weight: bold;
    flex-shrink: 0; 
}


.option-letter {
    font-weight: bold;
    margin-right: 1rem; 
}

.optiono-text {
    flex-grow: 1; 
    margin-left: 10px; 
    word-wrap: break-word; 
    white-space: normal; 
    overflow: hidden; 
    text-overflow: ellipsis;
   

}


.image-container img {
    max-width: 105%; 
    object-fit: contain;
    margin-left: -10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 0px;
    border-radius: 2px;
    background-color: #fafafa; 
   
 
}


.table-container {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 2px;
    background-color: #fafafa;
    
    
}


.alert {
    padding: 2px 20px 5px 20px;
    margin-top: 15px;
    border-radius: 5px;
    
    font-size: 0.75rem;
    max-height: 150px; 
    overflow: hidden;
    overflow-y: auto;
}

.alert-success p {
    
    color: #1c7932;
   
}

.alert-danger p {
   
    color: #721c24;
    
}



.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5rem; 
    z-index:100;
}
.navigation-buttons .btn i {
    color: rgb(245, 202, 231);   
    z-index:100;
}


.question-status {
    margin-top: 1rem;
    text-align: center; 
    color: azure;
    z-index: 100px;
}
.grid-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row; 
    gap: 2rem;
    z-index:200;
}
.answered {
    color: #6aa578;          
}

.not-answered {
  
    color: rgb(196, 186, 186);
}
.selected {
    box-shadow: 0 0 10px 5px rgba(0, 128, 255, 0.6); 
    border-radius: 50%;
    z-index: 200;
  }
  

  
.content-wrapper span[style*="text-decoration: underline"] {
   
    text-underline-offset: 5px;  
    text-decoration-thickness: 1.5px; 
    color: rgb(111, 111, 156);
    padding-left: 0.3em;
    padding-right: 0.3em; 
}

.option-container{
    gap: 2px;
}
.explanation-text {
    font-size: 0.75rem;
  
   color: #6aa578;
}

    
@media (max-width: 768px) {

    .main_container{
        width:100%;
    }
   
    .card {
        width: 100vw; 
        max-height: none;
        height:auto;
    }
    .card-body{
     padding-top:5px;
     padding-bottom:5%;
     max-height:none;
     height:auto;
    }
    .content-wrapper{
        max-height:none;
     height:auto;
    }
    .alert{
    max-height:none;
     height:auto;
    }
    .option {
        flex-direction: column; 
        align-items: flex-start;
    }

    .btn-option {
        width: 100%; 
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 10px;
        
    }
    
    .navigation-buttons {
        display: flex;
        justify-content: center;
        margin-top:5px;
        gap: 5rem; 
    }
    .grid {
        grid-template-columns: repeat(7, 1fr); 
        flex-direction: column;
    }
    .card-body .content-wrapper {
        flex-direction: column;
        max-height:none;
        height:auto;
    }
    
    .left-content{
        display: flex;
        flex-direction: column;
        flex:1;
        min-height:0px;
   
        
        overflow:hidden;
        overflow-y:auto;
        max-height:none;
        height: auto;
       

      }
      .passage-container{
        padding: 10px;
        overflow: auto;
        box-sizing: border-box;
        word-wrap: break-word; 
        max-height:100%;
      }
      .passage {
        text-align: justify;
        word-break: break-word; 
        word-wrap: break-word; 
        hyphens: auto;
        overflow: hidden;
        height: 100%;
    }
    
    .passage p {
        font-size: 16px; 
        color: #333; 
        margin: 0; 
        line-height: 1.5; 
        max-width: 100%; 
       
    }
   .right-content .card-title{
        font-size: 1rem;
    }
    .right-content{
        flex:1;
        padding-top:5px;
        padding-bottom:5%;
        max-height: 100%;
    }
   .grid-container{
    width: 90%;
   }
    .card-title { 
        font-size: 10px; 
        font-weight: 600;
        text-align: left; 
        
    }
    .content-divider {
        width: 100%; 
        max-height: 2px;
        padding-top:5px;
        padding-bottom:5px;
        margin: 20px 0; 
        border-left: none; 
        border-bottom: 2px solid #ccc; 
    }
    .optiono-text {
        font: 8px;
    }
}
