/* AdminApp.css */

/* General container for the layout */
.admin-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
  
  }
  

  /* Main content area */
  .main-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    transition: margin-left 0.3s ease;
  }
  
