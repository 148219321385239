

.Drop select {
  flex: 1;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 1.5rem;
  font-size: 1.19rem;
  color: #b8c2c5;
}


@media screen and (max-width: 430px),
  (max-width: 390px),
  (max-width: 768px),
  (max-width: 375px),
  (max-width: 414px),
  (max-width: 412px),
  (max-width: 360px) {
  .Drop {
    width: 55px;
    padding: 0;
  }
  .Drop select {
    width: 100%;
    font-size: 1rem;
  }
}
