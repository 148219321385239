.filter {
    position: absolute; /* Position the filter icon absolutely within the card */
    top: 2px; /* Adjust based on your layout */
    right: 50%; /* Adjust based on your layout */
    z-index: 1; /* Ensure it's on top of other content */
    font-size: 0.5rem;
  }
  
  .filter .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
    background: transparent; /* Make sure it's transparent */
  }
  