.errorContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}

.backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.errorContainer p {
    position: relative;
    color: white;
    text-align: center;
    z-index: 1; 
    animation: grow 10s ease forwards; /* Slower grow animation */
}

/* Grow Animation */
@keyframes grow {
    0% {
        transform: scale(0);          /* Start small */
        opacity: 0;                   /* Start transparent */
    }
    100% {
        transform: scale(1);          /* End at full size */
        opacity: 1;                   /* Fully visible */
    }
}

/* Fade-Out Animation */
.fadeOut {
    animation: fade 1s ease forwards; /* Fade out animation */
}

/* Fade Keyframes */
@keyframes fade {
    0% {
        opacity: 1;                   /* Start fully visible */
    }
    100% {
        opacity: 0;                   /* End fully transparent */
    }
}
