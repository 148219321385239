body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}
.support-page{
    overflow: hidden;
    height: 100vh;
    width: 100%;
    align-content: center;
    
}
.support-container {
    justify-content: center;
    max-width: 90%;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 20px;
    overflow-y: auto;
}

h1 {
    text-align: center;
    color: #333;
}

.support-option {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 15px 0;
    padding: 20px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.support-option i {
    font-size: 3rem;
    margin-right: 15px;
    margin-bottom: 1rem;
    color: #711876;
}

.support-price span {
    color: #b38de2;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.support-price span:hover {
    color: #df67a9;
    font-size: 1.2rem;
}

.PayNow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 20%;
    color: #fff;
    background: #5d1073;
    border-radius: 50px;
    font-size: 1.188rem;
    font-weight: 700;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
}

.PayNow:hover {
    background: #d25285;
    color: #e0e0e0;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border: none;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.support-details {
    flex: 1;
}

.support-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.support-description {
    font-size: 0.6rem;
    color: #555;
}

.support-price {
    font-size: 1.2rem;
    color: #af72f5;
    text-align: center;
    align-items: center;
    margin-top: 55px;
    margin-bottom: 55px;
}

/* Media query for screens with a max-width of 920px */
@media (max-width: 920px) {
    .support-page{
       height: 100vh;
   
      
    }
    .support-container {
        flex-direction: column; 
        height: 90vh;
        margin-top: 10x;
        gap:0px;

    
    }
    .support-details {
        flex: 1;
    }

    .support-option {
        width: 90%;
        height: 50%;
      
        margin-left: auto;
        margin-right: auto;
    
       
    }

    .PayNow {
        width: 80%; 
        height: 55px;
    }

    .support-price {
        font-size: 1rem; 
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .support-option i {
        font-size: 2rem;
        margin-right: 5px;
        margin-bottom: 0.5rem;
        color: #711876;
    }
}
