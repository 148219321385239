
.text {
  font-size: 2rem;
  font-weight: bold;
  font-family: Arial, sans-serif; 
  
}

.doubleColumn {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 60px;
  padding-right:auto;


}
.Drop-DownOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;

  
}



.wordCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 175px;
  width:250px;
  background-color: #fffdfd;
  border-radius: 5px;
  padding: 5px;

 
}

.wordCount input {
  width: 250px;
  font-size: 3.5rem;
  overflow: hidden;
  align-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
  color: #b8c2c5;
}

.wordCount ::placeholder {
  color: #b8c2c5;
  overflow: hidden;
  font-size: 1.18rem;

 
 
}

.DeliveryTurnaround,
.DocumentType {

  overflow: hidden;
  width: 100%; 
  z-index: 2;
  margin-bottom: -8px;
  margin-left: -50px;
  
}

.DocumentType select,
.DeliveryTurnaround select {

  font-size: 1.18rem;
  border: none;
  outline: none;
  border-radius: 5px;

  background-color: transparent;
  z-index: 3;

}

#word_count_icon {
  margin: 0;
  width: 57px;
}

.Drop img {
  width: 55px;
}










.gray {
  background: #eaeaea;
  color: rgb(199, 193, 193);
  border: none;
}



@media (max-width: 920px) and (min-width: 344px){
 .page{
  display: flex;
  flex-direction: column;
  height: 100%;
 }
 .doubleColumn {
  flex-direction: column;
  gap: 25px;
}

.wordCount {
  width: 270px;

}

}




