


.logo img  {
    max-width: 35px;
    max-height: 25px;
    margin-right: 10px;   
}
.logo{
    display:flex;
    flex-direction: row;
}

