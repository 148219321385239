.sticky-note {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 10px;
  background-color: #f8f4d4;
  border-radius: 5px;
  cursor: move;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  transform: rotate(-3deg);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: #6D6D6D; 
}

.sticky-note:hover,
.sticky-note:focus {
  transform: scale(1.1) rotate(6deg);
  box-shadow: 10px 10px 14px rgba(33,33,33,.7);
}

.note-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.note-title {
  width: 90%;
  height: 25%;
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: left;
  color: inherit;
}

.sticky-note textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background: transparent;
  outline: none;
  color: inherit;
}

.sticky-note .remove-note {
  position: absolute;
  top: 5px;
  right: 5px;
  transform: rotate(12deg);
  align-self: flex-end;
  cursor: pointer;
  margin-bottom: 5px;
  color: rgb(235, 126, 16);
  font-weight: bold;
  border: none;
  background-color: transparent;
  font-family: 'Reenie Beanie', cursive;
  padding-left: 0px;
}
