.containerMount {
  display: flex;
  position: relative;
  margin: 70px auto;
  overflow: hidden; 
  flex-direction: column;
  width: 60vw;
  height: 80vh;

  background:linear-gradient(#ffffff,   #fbe2ee54 );
 
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.169); 
  justify-content: center;
  
}
.containerMount .input {
  display:flex;
  align-content:justify;
  align-items: center;
  margin: 0px auto 20px auto;
  width: 80%;
  height: 5rem;
  background:#fffdfd;
  border-radius:10px;
  color:#b8c2c5;
  overflow: hidden;
}
.inputs input {
  
  width:45vw;
  background:transparent;
  border:none;
  outline: none;
  color:#b8c2c5;
  font-size:1.19rem;
  z-index: 2;
  overflow: hidden;
  margin-top: 25px;

}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  background-color: #c09f9f1b;
  
}
.header_text{
  font-size: 3rem;
  letter-spacing: 0.045em;
  font-family:Quattrocento Sans;
  margin-top:55px;

}
.page h4{
  font-size: 1.5rem;
  
  margin-bottom:10px;
  letter-spacing: -0.04em;
  font-family:Quattrocento Sans;
}


.underline {
  width: 3.81rem;
  height: 0.45rem;
  background: #5d1073;
  border-radius: 9px;
  margin-bottom:0px;
 
}

.containerMount p {
  font-size: 1rem;
  margin-top:20px; 
  font-family:Quattrocento Sans;
}



.link_two {
  position: absolute;
  left: 51%; 
  transform: translateX(-50%); 
  bottom: 105px;
  display: flex;
  gap: 10px; 
  color: #797979;
  font-size: 18px;
  justify-content: center;

}




.link_one {
  position: absolute;
  left: 51%; 
  transform: translateX(-50%);
  bottom: 105px; 
  display: flex;
  gap: 10px; 
  color: #797979;
  font-size: 18px;
  justify-content: center;

}


.submit-container {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 80px;
  max-height: 100px;
  position: fixed; 
  bottom: 80px; 

  max-width: 800px;
  background: transparent;

  z-index: 1000;
  box-sizing: border-box; 
}

.submit-container .submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 65px;
  color: #fff;
  background: #5d1073;
  border-radius: 50px;
  font-size: 1.188rem;
  font-weight: 700;
  cursor: pointer;
}

.submit-container .submit.gray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 65px;
  background-color: #c8bbbbc5;
  color: rgb(255, 255, 255);
  border-radius: 50px;
  font-size: 1.188rem;
  font-weight: 700;
  cursor: pointer;
  
}

.submit-container .submit:hover {
  background-color: #460b52;
  color: rgb(248, 163, 194);
}

.submit-container .submit.gray:hover {
  background-color:  #c8bbbbc5;
  color:rgb(248, 163, 194);
}

.link-with-space {
  white-space: nowrap; 
}

.wrap-text {
  white-space: normal; 
  margin-left: auto;
  margin-right:auto;
  margin-top:15px;
  font-family:Quattrocento Sans;
}


@media (max-width: 920px) and (min-width: 334px) {
  
  .header_text{
    font-size: 1rem;
    margin-top:5%;    }
  .underline{
    margin-bottom:10px;
  }
  .containerMount {
    width: 100vw;
    height: 70vh;
   
  }
 
  .containerMount p {
    font-size: 14px;
    
  }
  .wrap-text{
    font-size: 12px;
    margin-top:5px;
  }
  .submit-container {
    align-items: center;
    justify-items: center;
    max-height: 250px;
    max-width: 800px;
    bottom: 250px;
    gap: 30px;
  
  }

  .submit-container .submit{
    gap: 25px;
    box-sizing: border-box; 
    width: 150px; 
    height: 55px; 
    border-radius: 15px;
    padding: 10px;
    
   
  }
  .submit-container .submit.gray {
    gap: 25px;
    box-sizing: border-box; 
    width: 150px; 
    height: 55px; 
    border-radius: 15px;
    padding: 10px;
  }

  
}
