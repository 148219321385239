.quiz-container { 
    position: relative;
    width: 100%;
    height: 100vh; 
    background-color: #f0f4f8;
    display: flex;
    flex-direction: column;
    
}

.happy-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh;
    z-index: 0; 
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
    pointer-events: none; 
    padding-top:150px;
}

.happy-animation > div {
    width: auto; 
    height: auto;
    display: flex;
    align-items: center; 
    justify-content: flex-start; 
}


.confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;
}

.confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #FF5733; 
    opacity: 0.8;
    animation: fall 5s infinite;
}

.timer-message {
    font-weight: 300; 
    color: grey;
    font-size: 14px; 
    margin-top: 10px; 
}
.qr-and-message-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.monkey-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh;
    z-index: 0; 
    display: flex;
    justify-content: center;
    align-items: center; 
    pointer-events: none; 
}

.monkey-animation > div {
    width: 100%; 
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center; 
}
.monkey-message{
    margin-top: 50vh;
    
}
.generate-qr-container {
    position: absolute;
    bottom: -200px;
    left: 5px;
    z-index: 10; 
    background-color: #e6f0ff; 
    border: 1px solid #c1d4f7; 
    border-radius: 8px;
    padding: 20px;
    width: 395px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.qr-title {
    display: flex;
    align-items: center;
}
.generate-qr-text h2 {
    font-size: 1.2em;
    color: #333;
    margin: 0;
    font-weight: bold;
}

.generate-qr-text p {
    font-size: 0.9em;
    color: #666;
    margin: 5px 0 0 0;
}

/* Generate button */
.generate-button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #6A0DAD; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease;
}

.generate-button:hover {
    background-color: #5a0c91;
}
.paypal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9); 
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000; 
    text-align: center;
}


#paypal-buttons-container > div {
    margin: 0 auto;
}
.bullet-item {
    margin-top: 1em;
}
