

.mainContent {
    position: relative;
    width: 100%;
    height: 100vh; 
    display: flex;
    align-items: center; 
    justify-content: center; 
    background:linear-gradient(#ffffff,   #fbe2ee54 );
 
}
.container {
    position: absolute;
    width: 500px;
    height: 500px;
   
   
    display: flex;
    align-items: center; 
    justify-content: center; 
}

.box {
    position: absolute;
    width: 5rem;
    height: 5rem;
    transform-origin: center;
    animation: spawn 1s ease-out forwards, rotate 10s linear infinite; 
}

.box:first-child {
    position: relative; 
    transform: none; 
    animation: none; 
}


.Bubble_1,
.Bubble_2,
.Bubble_3,
.Bubble_4,
.Bubble_5,
.Bubble_6{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 2px solid #e8e6e8be;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}


@keyframes spawn {
    0% {
        transform: scale(0) translate(0px);
        opacity: 0;
    }
    100% {
        transform: scale(1) translate(200px);
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) translate(200px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translate(200px) rotate(-360deg);
    }
}
.box:nth-child(2) {
    transform: rotate(0deg) translate(250px); 
    animation: rotate 10s linear infinite .5s; 
}

.box:nth-child(3) {
    transform: rotate(90deg) translate(250px); 
    animation: rotate 10s linear infinite 2.5s; 
}

.box:nth-child(4) {
    transform: rotate(180deg) translate(250px); 
    animation: rotate 10s linear infinite 4.5s; 
}

.box:nth-child(5) {
    transform: rotate(270deg) translate(250px); 
    animation: rotate 10s linear infinite 6.5s; 
}

.box:nth-child(6) {
    transform: rotate(315deg) translate(250px); 
    animation: rotate 10s linear infinite 8.5s; 
}
@media (max-width: 798px) and (min-width: 344px){
.container {
    position: absolute;
    width: 500px;
    height: 500px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center; 
    justify-content: center; 
}
}