
.card .icon-container {
  position: relative;
  width: 3em; 
  height: 3em; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.card .icon-container::before {
  content: '';
  position: absolute;
  width: 4.5em;
  height: 4.5em; 
  border-radius: 50%;
  background-color: transparent; 
  z-index: -1;
}

.card .icon {
  position: relative;
  font-size: 1.5em; 
  z-index: 1; 
  background-color: transparent;
}




.card .content {
  flex: 1;
}

.news-item .news-icon {
  width: 24px;
  height: 24px;
  margin-right: 55px;
}

.dashboard-container {
  flex-grow:1;
  flex-direction: column;
  gap: 20px; 
}

.small-cards-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; 
}

.small-cards-row .card {
  flex: 1 0 21%; 
  max-width: 21%; 

}


.large-card-row {
  display: flex;
  flex-direction: column;
  z-index:10;
}
news-container{
  align-content: center;
}

@media (max-width: 920px) {
  .small-cards-row {
      flex-direction: column;
  }

  .small-cards-row .card {
      flex: 1 0 100%;
      max-width: 100%; 
  }

  .large-card-row {
      flex-direction: column;
  }
}

@media (max-width: 920px) {
  .small-cards-row {
      flex-direction: row;
      margin-left: 25px;
      width: 80%;
  }

  .large-card-row {
      flex-direction: column;

  }
}
