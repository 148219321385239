#header {
    transition: all 0.5s;
    z-index: 997;
    height: 40px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding: 5px;
    margin-top:0px;
    position: fixed;
}

.header-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

a {
    color: #b969a4;
    text-decoration: none;
    cursor: pointer;
}


