
.card {
  padding: 10px 15px;
  margin: 10px 0; 
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd; 
  border-radius: 8px; 
  background-color: #fff; 
}

.card .content {
  flex: 1;
}



.card-header {
  margin-bottom: 10px;
}

.card-title {
  margin-left: 0;
  display: flex;
  align-items: center;

  margin-bottom: 10px; 
  justify-content: center;
  font-size: 1.2em; 
}

.card-divider {
  margin: 8px 0;
  text-align: center;
  font-size: 1.2em;
  color: #ccc;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  margin-bottom: 16px;
}

.icon-container {
  position: relative;
  width: 3em; 
  height: 3em; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon-container::before {
  content: '';
  position: absolute;
  width: 4.5em; 
  height: 4.5em; 
  border-radius: 50%;
  background-color: #f0f0f0; 
  z-index: -1; 
}

.card-icon {
  position: relative;
  font-size: 1.5em; 
  z-index: 1;
  color: #ae1893;
}

.card-body {
  margin-top: 10px;
  text-align: center;

}



.card-amount-wrapper{
  margin-left: -3.8rem;

}
.card-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
}


.list-item:hover {
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2000;
}

.selected {
  background-color: #d0e9ff;
  border-color: #b3d4fc;
  z-index: 2000;
}


.card-danger {
  border: 1px solid #771966;
  background-color: #f9d4ec;
}

.card-warning {
  border: 1px solid #771966;
  background-color: #f0894e82;
}

.card-success {
  border: 1px solid #771966;
  background-color: #dff0d8;
}

.card-white {
  border: 1px solid #771966;
  background-color: #fff;
}

.card amount .text-danger {
  color: #d9534f;
  
}

.text-warning {
  color: #f0ad4e;
}

.text-success {
  color: #5cb85c;
}



.large-card{
  gap: 55px;
}

.news-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.news-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.news-content {
  flex-grow: 1;
  font-size: 0.5rem; 
}



.news-content h4 {
  margin: 0;
  font-size: 0.75rem;
}

.news-content p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.news-category {
  font-size: 0.8rem;
  color: #777;
}

.large-card-row-divider {
  border: 0;
  height: 1px;
  background: #555; 
  margin: 10px 0; 
}








@media (max-width: 992px) {
  .dashboard-container {
      align-items: center;
  }

  .small-cards-row {
      flex-direction: column;
      align-items: center; 
  }

  .small-cards-row .card {
      flex: 1 0 100%;
      max-width: 90%;
  }

  .large-card-row {
      flex-direction: column;
      align-items: center; 
  }
}

@media (min-width: 992px) {
  .small-cards-row {
      flex-direction: row; 
  }

  .large-card-row {
      flex-direction: column;
  }
}