

  



#user_icon {
  margin: 0 1.88rem;
  width: 55px;
}

#email_icon {
  margin: 0 1.88rem 0.625rem;
  width: 55px;
}

.input ::placeholder {
  color:#bbc7ca;
  width:fit-content; 
  overflow: hidden;

}





  
  .text-danger {
    color: red;
    display: block; 
    margin-top: 5px; 
    font-size: 12px;
    white-space: nowrap; 
    margin-right: 15px;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-top: -5px;
    width: 450px;
  }
  
  .checkbox-container button {
   
    height: 15px;
    border: 2px solid #b8c2c5; 
    border-radius: 50%; 
    background-color: transparent; 
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;

  }
  
  .checkbox-container button.checked {
    background-color: #5d1073; 
    border-color: #5d1073; 
  }
  
  .checkbox-container p {
   
    font-size: 1rem; 
    color: #b8c2c5; 
    text-align: left;
  }
  
  
@media (max-width: 920px) and (min-width: 334px) {
  

  
  

  .privacy
  {
    width: 100%;
    font-size: 1rem;
    gap:0px;
  
  }
  .terms {
    width: 100%;
    font-size: 1rem;
    
  }

  
  #user_icon {
    margin: 0 1.88rem;
    width: 55px;
    
  }
  
  #email_icon {
    margin: 0 1.88rem 0.625rem;
    width: 55px;
  }
  
  .input ::placeholder {
    color:#bbc7ca;
    width:fit-content; 
    overflow: hidden;
  }
  
  
  
  .input input {
    height:3rem;
    width:100%;
    font-size: 1rem;
  }

  }
 