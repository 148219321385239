
.banner {
  position: fixed;
  bottom: 5px;
  height:12%;
  left: 0;
  width: 100%;
  background-color: #ffeb3b;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  justify-content: center;




  z-index: 7000;
}

.banner-text {
  padding-left: 10px;
  color: #101010;
 
  
  
}

.banner-content {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  color: #101010;
  align-items: center; 
  
  
  
 

}

.save-button {
  background-color: #3d50f6;
  color: white;
  border: none;
  padding: 5px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 auto;
  
 
}
.save:hover{
  color:#ffeb3b
}

.cancel-button {
  background: none;
  position: absolute;
  top:10px;
  transform: translateY(-50%);
  color: #f30808;
  

  font-size: 10px;
  cursor: pointer;
  border: none;
  margin-left: 96%;
 
 
}

.cancel-button:hover {
  color: #333; 
}

@media (max-width: 535px) and (min-width: 334px) {
.banner-content {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  font-size: 0.85rem;
  color: #101010;
}
}