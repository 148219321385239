.header-nav ul {
    list-style: none;
}

.header-nav > ul{
    margin:0;
    padding: 0;
}

.header-nav .nav-icon {
    font-size: 26px;
    color: #012978;
    margin-left: 5px;
    position: relative;
}

.header-nav .nav-profile{
    color: #012978;
}

.header-nav .nav-profile {
    color: #012978;
}

.header-nav .nav-profile img{
    max-height: 36px;
}

.header-nav .nav-profile span{
 font-size: 14px;
 font-weight: 600;   
}

.header-nav .badge-number{
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}

.header-nav .notifications{
    inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notifications-item{
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .notifications .notification-item i{
    margin: 0 20px 0 10px;
    font-size: 14px;
}

.header-nav .notifications .notification-item h4{
    font-size: 16px;
    font-weight: 600;
    margin-bottom:-2px;
}

.header-nav .notifications .notification-item p{
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 3px;
    
    color: #919191;
}

.header-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
}

.header-nav .messages{
    inset: 8px -15px auto auto !important;
}

.header-nav .message-item{
    padding: 1opx 10px;
    transition: 0.3s;
}

.header-nav .message-item a{
    display:flex;
}

.header-nav .message-item img{
    margin: 0 20px 0 10px;
    max-height: 40px;
}
.header-nav .message-item h4{
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 2px;
    color: #444444;
}

.header-nav .message-item p {
    font-size: 12px; /* Increased size for readability */
    margin-bottom: 2px;
    font-weight: 600; /* Made heavier */
    color: #000000; /* Made darker */
}
.header-nav .message-item:hover{
    background-color: #eecaeb3c;
}
/* Adjust delete button styling within message-item */
.header-nav .message-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-nav .message-item .btn.btn-danger.btn-sm {
    font-size: 12px; /* Adjust size as needed */
    background-color: #583169; /* Soft dark purple color */
    border-color: #583169; /* Match border color */
    color: white; /* Text color */
    margin-left: 10px; /* Add some space between text and button */
}

.header-nav .message-item .btn.btn-danger.btn-sm:hover {
    background-color: #6b3a92; /* Slightly lighter shade for hover */
    border-color: #6b3a92;
}
.header-nav .message-divider {
    border: 0;
    border-top: 2px solid #583169; /* Adjust color as needed */
    margin: 0.5rem 0; /* Adjust spacing as needed */
}
 

.header-nav .profile{
    min-width: 240px;
    padding-bottom: 0;
    top:8px !important;
}

.header-nav .profile .dropdown-header h6{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color:#444444;
}
.header-nav .profile .dropdown-header span{
    font-size: 14px;
}

.header-nav .profile .dropdown-item {
    font-size: 14px;
}

.header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}
.header-nav .profile .dropdown-item:hover{
    background-color: #f6f9ff;
}