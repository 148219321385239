.search-bar {
    min-width: 360px;
    padding: 0 20px;
    margin-left: 30%;
}

@media (max-width: 1199px) {
    .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.search-form {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative; /* Ensures proper positioning of elements */
}

.search-form input {
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 40px 7px 10px; /* Add right padding to prevent overlap */
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width */
}

.search-form input:focus, .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
}

.search-form button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    position: absolute; /* Position the button inside the input field */
    right: 10px; /* Adjust to position the button correctly */
    font-size: 16px; /* Adjust the font size here */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.search-form button i {
    color: #012970;
    font-size: 16px; /* Adjust the icon size here */
}
