.header p {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center; 
}

h4 {
    margin-left: 10%;
    text-align: justify; 
    width: 80%; 
}

p {
    margin: 0 auto;
    text-align: justify; 
    width: 90%; 
    color: #ada8a8;
}
