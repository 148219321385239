.login-signup-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width:260px;
    margin-left:10vw;
    z-index:200;
}

.login-signup-button:hover {
    background-color: #0056b3;
}


.message {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    background-color: #ffffff;
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    max-width: 350px; 
    margin-left:7vw;
    padding: 10px 20px;
    margin-top:20%;
   
}

h2 {
    font-weight: bold;
    font-size: 1.5rem; 
    margin-bottom: 10px; 
    color: #333; 
}

p {
    font-size: 1.25rem; 
    margin: 5px 0; 
    color: #555; 
}

.score {
    font-weight: bold; 
    font-size: 2rem;
    color: #007BFF; 
    margin: 10px 0; 
}

.confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; 
    overflow: hidden; 
    z-index: -1; 
}

.confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #FF5733; 
    opacity: 0.8;
    animation: fall 5s infinite;
}

@keyframes fall {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh); 
    }
}

