.account-type-page {
    height: 100vh;
    width: 100vw;
    background-color: #f7f7f7; /* Lighter background */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.account-type-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    max-width: 850px;
    height: 80vh;
    padding: 30px;
    
     background:linear-gradient(#ffffff,   #fbe2ee54 );
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Soft shadow */
    justify-content: center;
    text-align: center;
}

.account-type-text {
    font-family: 'Arial', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #6a2c7e; /* Rich purple */
    margin-bottom: 20px;
}

.container-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
}

.account-options button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 12px;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden;
}

.account-options button:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow on hover */
}

.account-options button .icon {
    font-size: 3rem;
    color: #6a2c7e; /* Rich purple for icon */
    margin-bottom: 15px;
}

.account-options button span {
    font-size: 1.6rem;
    font-weight: 700;
    color: #333333; /* Dark gray for text */
    text-transform: capitalize;
    transition: color 0.3s ease;
}

.account-options button:hover span {
    color: #b4509a; /* Soft pink on hover */
}

@media (max-width: 920px) {
    .account-type-container {
        width: 100%;
        height: auto;
        padding: 20px;
    }

    .container-options {
        flex-direction: column;
        gap: 30px;
    }

    .account-options button {
        width: 200px;
        height: 200px;
    }

    .account-type-text {
        font-size: 2rem;
    }
}
