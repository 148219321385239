#main {
    margin-top: 60px;
    padding: 5px 5px;
    transition: all 0.3s;
    position: relative; 
    z-index: 20; 

    @media (max-width: 1199px){
        #main {
            padding: 5px;
        }
    }
}
