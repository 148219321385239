/* Support.css */
.support-table {
    border-collapse: collapse;
    width: 90%;
    padding:0px 20px 10px 20px;
    margin-left: 70px;
    align-content: center;
    transition: all 0.3s ease;
  }
  
  .support-table.collapsed {
    width: 90%;
    height: 100%;
  }
  
  .support-table-header {
    border-bottom: 5px solid lightgrey;
    padding: 10px;
    font-size:  10px;
  }
  
  .support-table-cell {
    border-bottom: 5px solid lightgrey;
    text-align: center;
    padding: 5px;
  }
  #Terms{
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 920px) {
    .support-table{
        margin: 30px 40px 0px 30px;

    }
    .support-table-header {
      padding: 8px;
    }
  
    .support-table-cell {
      font-size: 8px;
      padding: 5px;
    }
  }
  
