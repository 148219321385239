
.stripe {
    width: 102%;
    height: 5px;
    background-color: #dc32a9;
    position: relative;
    top: 40px;
    z-index: 1200;
    position: fixed;
}

.about-us-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    background-color: #fff;
    z-index: 0;
    cursor: grab;
    overflow-x: hidden;
    align-items: flex-start;
    flex-wrap: wrap;
}

.about-us-container::-webkit-scrollbar {
    display: none;
}

.about-us-container {
    scrollbar-width: none;
}

.about-us-container:active {
    cursor: grabbing;
}

.about-us-text {
    width: 55%;
    text-align: justify;
    word-break: break-word;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 100px);
    padding-right: 15px;
    padding-bottom: 50px; 
    scrollbar-width: none;
}

.about-us-text::-webkit-scrollbar {
    display: none;
}

.about-us-text h1 {
    font-size: 46px;
    font-weight: bold;
    margin-top: 10%;
    margin-bottom: 20px;
    text-align: center;

}

.about-us-text p {
    font-size: 1.2rem;
    font-weight: normal;
    text-align: justify;
    word-spacing: 0.05rem; 
    hyphens: none;
    margin-left: 55px;
}



.about-us-image-container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.about-us-image {
    width: 50%;
    text-align: right;
    top: -55%;
    position: absolute;
    transform: translateY(-10%);
}

.about-us-image img {
    max-width: 100%;
    height: auto;
    display: block;
}

.explore {
    position: fixed; 
    bottom: 20px;
    right: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 10vh;
    color: #fff;
    background: #5d1073;
    border-radius: 50px;
    font-size: 1.188rem;
    font-weight: 700;
    cursor: pointer;
}

.explore span {
    display: inline-block;
    vertical-align: middle;
}

.explore:hover {
    background-color: #460b52;
    color: rgb(248, 163, 194);
}

@media (max-width: 1000px) and (min-width: 344px) {
    .about-us-container {
        padding: 20px 10px;
        flex-direction: column;
        align-items: center;
        overflow: visible;
        height: auto;
    }
    .about-us-text h1 {
        font-size: 46px;
        font-weight: bold;
        margin-top: 18%;
        margin-bottom: 20px;
    }
    
    .about-us-text p {
        font-size: 1.2rem;
        font-weight: normal;
        text-align: justify;
        text-justify: inter-word; 
        letter-spacing: 0.08rem; 
        word-spacing: 0.5rem;
        hyphens: auto;
        word-break: keep-all;
        overflow-wrap:normal;
        
        margin-left: 25px;
    }
    

    .about-us-text {
        width: 90%;
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 150px; 
    }

    .about-us-image-container {
        width: 100%;
        height: 50vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
    }

    .about-us-image {
        width: 100%;
        height: auto;
        transform: rotate(180deg) translateY(-40%);
        transform-origin: center bottom;
        margin-top: 0;
    }

    .explore {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 255px;
        height: 50px;
        font-size: 0.9rem;
        background: #5d1073;
        color: #fff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
    }

    .explore:hover {
        background-color: #460b52;
        color: rgb(248, 163, 194);
    }
}
