.register-Deliverable {
  position: fixed;
  top: 60px;
  left: 0;
  width: 95%;
  height: 91%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}



.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.register-Deliverable form,
.search-Deliverable {
  background: white;
  padding: 30px;
  border-radius: 5px;
  width: 100%; 
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 5px; 
}
.search-Deliverable-return input
{
  margin-top: 5px;
  margin-bottom: 10px;

  
}
search-Deliverable-return select{
  margin-top:10px;
 
}
.search-Deliverable-return {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%;
  box-sizing: border-box; 
}

.register-Deliverable input,
.register-Deliverable select {
  width: 90%;
  padding: 10px;
  box-sizing: border-box;
  height: 55px;

}

.cost-container {
  display: flex;
  width: 90%;
  gap: 150px;
  
}

.cost-container input {
  flex: 1; 
  text-align: center;
}

.register-Deliverable button {
  padding: 10px;
  background: #5d1073;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-buttons {
  display: flex;
  gap: 10px;
 
}

.toggle-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
}

.toggle-button.active {
  background: #5d1073;
  color: white;
}

.toggle-button:not(.active) {
  background: grey;
  color: white;
}


.test-endpoint {
  text-align: center; 
}

.test-endpoint button {

  background: #ffffff;
  color: rgb(231, 204, 228);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 40%;
  margin-top: -10px;
}

.date-container {
  position: relative; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  width: 100%; 
  gap: 10px; 
}

.date-label {
  position: absolute;
  top: 20%; 
  left: 36%;
  transform: translate(-50%, -50%); 
  pointer-events: none; 
  padding: 0 5px; 
  width: 60%; 
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: start;
  background-color: #eaeaea;
  box-sizing: border-box; 
}

input[type="date"]:focus + .date-label,
input[type="date"]:valid + .date-label {
  display: none; 
}

input[type="date"] {
  padding-left: 10px;
  font-family: inherit;
  font-size: inherit;
}

.upload-status {
  margin-top: 10px;
  color: #007bff; 
}

.upload-status p {
  font-weight: bold;
}

.search-quiz{
  margin-left:60px;
}
.edit-quiz {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.edit-quiz textarea {
  width: 90%;
  height: 300px;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: vertical;
  white-space: pre;
  overflow-x: auto; 
  overflow-y: hidden; 
  cursor: grab;
}

.edit-quiz textarea:active {
  cursor: grabbing; 
}
.error-border {
  border: 2px solid red;
}


.pagination-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 18%;
  gap: 100px;
}

.pagination-buttons button {
  padding: 5px;
  background: #5d1073;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.auto-save-message {
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: center;
}
.auto-save-success {
  color: #7fd577 !important; 
}

.auto-save-error {
  color: #df6f07e1 !important; 
}

.toggle-pill {
  display: flex;
  justify-content: center;
  margin-bottom: 1em; 
}

.toggle-pill-btn {
  border: none; 
  background-color: transparent !important;
  cursor: pointer;
  padding: 0; 
  display: flex; 
  align-items: center; 
  gap: 0.5em; 
  outline: none; 
}
.toggle-pill-btn:hover {
  background-color: transparent !important; 
}

.toggle-pill-btn i {
  font-size: 1.5em; 
  color: #007bff; 
  transition: color 0.2s; 
}

.toggle-pill-btn:hover i {
  color: #0056b3;
}
