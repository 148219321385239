.blog-container{
  overflow:hidden;
  height: 100vh;
  width: 100%;

  
}
.content-container {
  flex-grow: 1; 
  box-sizing: border-box;
  flex-direction: column;  
 
  padding-left: 17%;
  padding-right: 10%;
  padding-bottom: 200px;
  padding-top:55px;
  transition: margin-left 0.3s ease;    
  width: 100%;             
  height: 100vh;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  text-align: justify;
  overflow-wrap: normal;
  hyphens: auto;
 
}
.content-container p{
  margin: 5px auto 5px 40px;
}

.AdministrativeHearing{
  margin-left: 5px;
}

.blog-container  h4 {
  font-size: 2.2rem;
  text-align: left;
  width: 100%;
  margin-top: -50px;
  margin-bottom:0px;
  padding: 90px 0 15px 0px;
  margin-left: 25px;
  color: #757171;

}
.blog-container  h2 {
  font-size: 1.5rem;
  font-family: 'Quattrocento Sans';
  text-align: left;
  line-height: 1.2;
  width: 100%;
  margin-left:65px;
}








.centeredItemStyle {
  display: flex;
  flex-direction: column;
  align-items: justify;
  padding-left: 5%;
  word-wrap: beak-word;
  padding-right: 5%;
  
  margin-bottom:-15px;
  color: #696767;
};

@media (max-width: 920px) and (min-width: 334px) {


  
}