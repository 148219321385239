@import url(https://fonts.googleapis.com); 
@import url(https://fonts.gstatic.com);
@import url(https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);

:root{
  scroll-behavior: smooth;
  --primary: #A250A0;
  --second: #FA60A8;
  --bgColor: #f6f9ff;
}

*, *::before, *::after {
  box-sizing: border-box;
}
 body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Quattrocento Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background-color: #ffff;
    overflow-x: hidden; 
}
a{
  color: #b969a4;
  text-decoration: none;
  cursor: pointer;
  
}
a:hover{
  color: #e89ece;
  text-decoration:none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quattrocento Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, #root {
  height: 100%; 
}

* {
  scrollbar-width: none; 
}

*::-webkit-scrollbar {
  display: none; 
}

html, body {
  overflow: hidden;
}

@media (max-width: 535px) and (min-width: 344px) {
  
  p {
    font-size: 11px; 
  }
  
}
