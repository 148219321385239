.flipbook-footer {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%; 
  height: 37px;
  text-align: center;
  background-color: #6e6e6e;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; 
  z-index: 10;
 
  padding: 8px; 
}



.flipbook-footer button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #9f9f9f;
  padding: 15px;
}

.flipbook-footer button i {
  margin-right: 0px;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: transparent;
}

.star-rating .fa-star {
  font-size: 1rem;
  cursor: pointer;
  color: #ccc;
  background-color: transparent;
  border: none; 
  outline: none; 
  padding: 0;
}

.star-rating .fa-star.selected {
  color: rgba(245, 139, 173, 0.984);
  background-color: transparent; 
}


.banner{
  height: 8%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}
.index-button{
  background-color: #d4f8d4;
}