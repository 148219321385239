


.add_text {
  margin-top: 55px;
  font-family: Arial, sans-serif; 
}


form {
  width: 100%;
  margin-top:20px;
  z-index: 7000;
}


.services input[type="checkbox"] {
  display: none;
}

.services input[type="checkbox"] + label {
  display: inline-block;
  padding: 25px;
  background: #eaeaea;
  border-radius: 6px;
  color: #b8c2c5;
  cursor: pointer;
  text-align: center;
  width: 125px;
  height: 65px;
  font-size: 0.75rem;
  transition: transform 0.1s ease, box-shadow 0.1s ease, background-color 0.1s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.services input[type="checkbox"] + label:hover {
  transform: scale(0.95); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  background-color: #5d1073;
 
}

.services input[type="checkbox"]:checked + label {
  background-color: #5d1073;
  color: #fff;
}


.tooltip {
  position: absolute;
  width: auto; 
  max-width: 400px; 
  height: auto;
  background-color: #b9267c5e;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
  visibility: hidden;
  opacity: 0;
  margin-top: 29%;
  margin-left:45%;
  transition: opacity 0.3s ease, transform 0.3s ease;

}

.tooltip.visible {
  visibility: visible;
  opacity: 1;

}



.services {
  margin-top:-55px;
  display: grid;
  grid-template-columns: repeat(5, 2fr);
  row-gap: 5px;
  column-gap: 5px;
  z-index: 2;
  padding: 55px;
  position: relative;
}
.submit-container_add {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  max-height: 100px;
  position: fixed; 
  bottom: 80px; 
  max-width: 800px;
  width: 100%;
  background: transparent;
  z-index: 10;
  box-sizing: border-box;
  margin: 0 auto; 
}

.submit-container_add .submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 65px;
  color: #fff;
  background: #5d1073;
  border-radius: 50px;
  font-size: 1.188rem;
  font-weight: 700;
  cursor: pointer;
}

.submit-container_add .submit.gray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 65px;
  background-color: #eaeaea;
  color: rgb(199, 193, 193);
  border-radius: 50px;
  font-size: 1.188rem;
  font-weight: 700;
  cursor: pointer;
  
}

.submit-container_add .submit:hover {
  background-color: #460b52;
  color: rgb(248, 163, 194);
}

.submit-container_add .submit.gray:hover {
  background-color: #c8c8c8;
  color: #fff;
}


@media (max-width: 535px) and (min-width: 344px) {

  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 15px;
    z-index: 2;
    padding: 85px;
  }

  .service-item {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    position: relative;
    z-index: 3;
  }

  .services input[type="checkbox"] + label {
    display: inline-block;
    padding: 5px 5px;
    background: #eaeaea;
    border-radius: 6px;
    color: #b8c2c5;
    cursor: pointer;
    text-align: center;
    width: 70%;
    height: 70%;
    transition: transform 0.1s ease, box-shadow 0.1s ease, background-color 0.1s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .services input[type="checkbox"]:checked + label {
    background-color: #5d1073;
    color: #fff;
  }

  .submit-container_one {
    max-height: 200px;
    margin-right: 55px;
  }
  

  .submit-container_add {
    align-items: center;
    justify-items: center;
    max-height: 250px;
    max-width: 800px;
    bottom:200px;
    gap: 12px;
  
  }

  .submit-container_add .submit{
    gap: 25px;
    box-sizing: border-box; 
    width: 170px; 
    height: 45px; 
    border-radius: 15px;
    padding: 10px;
    margin-bottom:20px;
  }
  .submit-container_add .submit.gray {
    gap: 25px;
    box-sizing: border-box; 
    width: 170px; 
    height: 45px;
    border-radius: 15px;
    padding: 10px;
  }

  
}

