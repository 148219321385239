.flipbook-container {
  display: flex;
  height: calc(90vh - 20px);
  width: calc(90vw - 0px);
  margin-top: calc(11vh - 0px); 
  background: transparent;
  overflow: hidden; 
  box-sizing: border-box;
  background: transparent;
  
}

.flipbook-wrapper {
  display: flex;
  height: 100%;
  
 
 
}

.flipBookpage {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden; 
  width: 100%;
  height: 100%;
}
.flipbook img {
  width: 100%; 
  height: auto; 
  display: block; 
  object-fit: contain; 
}


.page-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  width:40px;
  height: 100%;
  pointer-events: none;
}

.left-gradient {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(51, 35, 35, 0.145));
}

.right-gradient {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.04));
}

.page-count {
  font-size: 1rem;
  color: #9f9f9f;
}



.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.thumbnail img {
  width: 100px;
  height: auto;
  margin-bottom: 5px;
}

.thumbnail.active img {
  border: 3px solid rgba(245, 139, 173, 0.984);
  border-radius: 4px;
}

.page-number {
  font-size: 1rem;
}

.double-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.double-page-wrapper {
  display: flex;
  align-items: center;
}

.thumbnail-page {
  position: relative;
}

.thumbnail-page img {
  width: 50px;
  height: auto;
}

.thumbnail-page .page-gradient {
  width: 45px;
}





.index-button{
  background-color: #d4f8d4;
}


@media (max-width: 920px) and (min-width: 344px) {
  .flipbook-container {
    overflow-x: auto; 
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; 
    width: 100%; 
    height: 90%;
    display: flex; 
    justify-content: flex-start; 
    align-items: flex-start; 
    box-sizing: border-box; 
    padding: 0; 
    margin-top:110px;
  
    position: relative; 
  }
  flipbook-wrapper{
    width: auto;
    
  }
 
}
