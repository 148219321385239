.flipBookSidebar {
  position: absolute;
  transition: transform 0.3s ease;
  transform: translateX(0);
  left: 0;
  top: 0;
  width: 150px;
  max-height: 100%; 
  background-color: rgba(190, 153, 166, 0.13);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  overflow-y: auto; 
  z-index: 9;
  padding: 10px;
  box-sizing: border-box;
}

.flipBookSidebar.horizontal {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px; 
  max-height: none;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(190, 153, 166, 0.13);
  overflow-x: auto; 
  overflow-y: hidden;
  white-space: nowrap;
  z-index: 10;
}


.thumbnail {
  padding: 5px;
  cursor: pointer;
  flex-shrink: 0;
}

.thumbnail.active img {
  border: 3px solid rgba(245, 139, 173, 0.984);
  border-radius: 4px;
}

.page-number {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}


@media (max-width: 1000px) {
  .flipBookSidebar {
    position: absolute;
    width: 100%; 
    height: 100px; 
    left: 0;
    bottom: 0; 
    max-height: none;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(190, 153, 166, 0.13);
    z-index: 20;
    padding: 5px;
    box-sizing: border-box;
  }

  .flipBookSidebar.horizontal {
    position: fixed;
    flex-direction: row;
    margin-top: 680px;
    left: 0;
    height: 180px;
    width: 100%;
    max-height: none;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .flipBookSidebar.horizontal .thumbnail {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
    flex-shrink: 0;
  }

  .flipBookSidebar.horizontal .thumbnail img {
    width: 80px;
    height: auto;
    margin-bottom: 5px;
  }

  .flipBookSidebar.horizontal .page-number {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }
}
