.sidebar {
    position: fixed;
    top: 66px;
    left: 0;
    transition: width 0.3s ease;
    height: 100vh;
    background-color: #e9ecef;
    padding-left: 15px;
  
    overflow-y: auto;
    z-index: 200;
  }
  
  
  /* Sidebar styling */
  .sidebar {
    
    transition: width 0.3s ease; /* Smooth transition */
  
    color: white;
    padding: 20px;
  }
  
  .sidebar.collapsed {
    width: 80px; /* Width when collapsed */
  }
  
  
  
  
  .sidebar-item {
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
 

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
   
    
}



.sidebar-nav .nav-item {
    margin-bottom: 22px;
    background-color: transparent;
    cursor: pointer;
   
    
   
}
.client-sidebar-icon{
   
    margin-right: 10px !important;
    color: #757171;
    font-size: 32px;
}



.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

  .sidebar-nav .nav-item .nav-link {
    color: black !important; 
    
  }
  .sidebar-nav .nav-item .nav-link:hover {
    color: #ce3094 !important;  /* Light purple color on hover */
  }

 

.sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
    
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}



.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;  
    
}



.sidebar.collapsed .sidebar-nav .nav-item .nav-link span {
display: none; /* Hide the text */
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}


@media (max-width: 920px) {
    .sidebar {
        left: 0;  /* Sidebar starts visible on smaller screens */
        width: 65px;
    }
    .sidebar.expanded {
        display: flex;
        flex:1;
        width: 5px;
        background-color: transparent;
      }
      
}


@media (min-width: 1200px) {
    #main,
    #footer {
        margin-left: 200px;  
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: -200px;  /* Move sidebar out of view when toggled on smaller screens */
    }
}

@media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;  /* Reset margin when sidebar is toggled */
    }
    .toggle-sidebar .sidebar {
        left: -200px;  /* Move sidebar out of view when toggled */
    }
}
