#confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #ffffff, #f0f0f0); 
  position: relative;
  background-image: url('../Assets/rocket_icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left:-10%;
  
  
}

.paypal_container {
  background-color: aliceblue;
  position: absolute;
  display: flex;
  flex-direction: column;
  
  align-items: center;

  border-radius: 0.43em;
  box-shadow: 0 1.25em 2.18em rgb(1, 28, 71, 0.3);
  top: 45%; 
  left: 67%; 
  transform: translate(-70%, -50%);
  z-index: 2; 
  width: 80%; 
  max-width: 400px; 
  overflow: hidden;
  height: 70%;

  
}

#paypal-buttons-container {
  width: 75%;
  align-content: center;
  margin: 1rem;

  max-height: 100%;
  overflow-y: scroll; 
  overflow-x: hidden; 


  &::-webkit-scrollbar {
    width: 0px; 
    background: transparent;
  }

 
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.total_cost h2 {
  font-size: 2.5rem;
  margin-top:10px;


}

.coupon_section input {
  border: none; 
  background-color: aliceblue;
  font-size: 0.6rem;
  text-align: center; 
  padding: 8px; 
  width: 100%;
}
.coupon_section input ::placeholder{
  display: flex;
  border: none;
  background-color: aliceblue;
  justify-content: center;
  font-size: 1.18rem;
  color:#b8c2c5;
  text-align: center;
  
}

.apply {
  display:flex;
  justify-content:center;
  align-items:center;
  width:350px;
  height:10vh;
  color:#fff;
  background:  #5d1073;
  border-radius: 50px;
  font-size:1.188rem;
  font-weight:700;
  cursor:pointer;
  margin-bottom:55px;

}
.hide {
  display: none;
}


@media (max-width: 1024px) and (min-width: 344px){
  #confirmation{
    background-image: none;
    }
  
  .apply {
    border-radius: 25px;
    width: 155px;
    height:55px;
   
  }
  
  .paypal_container {
    margin-left:15px;
    height: 50%;
  
    
  }
  #paypal-buttons-container {
    width: 100%; 
    height: 90%;
    align-content: center;
    padding: 15px;
 
    overflow: hidden;
    overflow-y: auto;
  }
  .total_cost h2 {
    font-size: 2rem;
    margin-top:55px;
  
  
  }
}