.sat-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f4f8;
    text-align: center;
    margin:5px 8px 5px 5px;
}

.loading {
    font-size: 2rem;
    color: #555;
}

.quiz-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
