.contacts-banner {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-content {
  background: white;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}

.toggle-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.toggle-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
}

.toggle-button.active {
  background: #5d1073;
  color: white;
}

.toggle-button:not(.active) {
  background: grey;
  color: white;
}

.search-contacts ul {
  list-style-type: none;
  padding: 0;
  
}

.search-contacts li {
  padding: 5px 0;
 
}

.view-contact input {
  width: 90%;
  height: 55px;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-left:5%;
}

.view-contact button {
  padding: 10px;
  background: #5d1073;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.contact-name{
  font-size: 0.9rem;
  color:black;
}
.last-login {
  font-size: 0.9rem; /* Adjust size for the entire line */
}

.last-login-label {
  color: inherit; /* Keep the normal text color for the label */
}

.last-login-date {
  color: black; /* Default color for dates */
}

.last-login-date.recent-login {
  color: green; /* Green color for logins within the last 24 hours */
}
