.progress-wrapper {
    width: 100%;
    margin-top: 15px;
    text-align: center;
}

.progress {
    width: 80%; 
    height: 8px;
    background: #e0e0e0; 
    margin: 0 auto; 
    border-radius: 4px; 
    overflow: hidden;
}

.progress-bar { 
    height: 100%;
    background: #76c7c0;
    width: 0; 
    transition: width 0.3s ease;
}
