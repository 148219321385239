
.container {
    position: relative;
    justify-content: center;
    max-width:100;
}

.header {
    margin-top: 55px;
    text-align: center;
    font-family: Arial, sans-serif; 
    font-size: 2rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    
}
.underline, .overlay_underline {
    width: 3.81rem;
    height: 0.45rem;
    background: #5d1073;
    border-radius: 9px;
    margin-bottom:22px;
    margin-top:11px;
    margin-left:45%;
  }


.Response {
    text-align: center;
}


.header_text h1 {
    font-size: 2rem;
    margin-top:150px;

    text-align: center;
}

.upload_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 0 20px;
    text-align: center;
    background-color: #ffffff;
    width: 600px; 
    max-width: 90%; 
    position: relative;
    padding-top: 55px;
    padding-bottom: 55px;
    border-radius: 0.43em;
    z-index: 2;
    margin: auto;
    margin-bottom: 1vh;
    overflow-y: auto; 
    max-height: 90vh; 
}
.overlay_header {
    font-family: Arial, sans-serif; 
    font-size: 2rem; 
    color:  #040105;
    text-align: center; 
    margin-top: 55px; 
    margin-bottom: 20px; 
    font-weight: bold;
}


input[type="file"] {
    display: none;
}


label {
    display: block;
    background-color: #b8c2c5;
    color: #ffffff;
    font-size: 1.12em;
    font-weight: 500;
    text-align: center;
    width: 18.75em;
    padding: 0.7em 0;
    margin: 1em auto; 
    border-radius: 0.31em;
    cursor: pointer;
}


#num-of-files {
    font-weight: 400;
    text-align: center;
    margin: 1.25em 0 1.87em 0;
}


ul {
    list-style-type: none;
    padding: 0;
}


.upload_container li {
    font-weight: 400;
    font-size: 0.5rem;
    background-color: #eaeaea;
    color: #5d1073;
    width: 100%;
    padding:0 0 10px 20px;
    align-content: center;
    height:25px;
    margin-bottom: 1em;
    
    border-radius: 0.3em;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
}

#files-list {
    max-height: 200px; 
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

#files-list::-webkit-scrollbar { 
    display: none;  
}


* {
    scrollbar-width: none; 
}

*::-webkit-scrollbar {
    display: none; 
}


html, body {
    overflow: hidden;
}

.upload_container button[type="submit"] {
    background-color: #5d1073;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.upload_container button[type="submit"]:hover {
    background-color: #0056b3;
}



.upload_container .fileContainer input[type="text"].has-border {
    border: 1px solid #ccc;
}

.container {
  position: relative;
  justify-content: center;
  max-width: 100%;
}

.overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    padding: 100px 20px 55px 20px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    
    border-radius: 0.43em;
    z-index: 3;
    margin: auto; 
   
    overflow-y: auto;
    max-height: 90vh;
}


.overlay > * {
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
    width: 600px;
    position: relative;
    margin-top: -100px;
    z-index: 2;
  
    overflow-y: auto; 
    max-height: 90vh;
}

.overlay button:hover {
    background-color: #0056b3;
}

.overlayContent {
    display: flex;
    flex-direction: column;
    max-width:100%;
   
 
}
.overlayContent textarea, input
 {
    border: 1px solid #ccc;
    outline: none;
    background-color: #ffffff;
    padding: 10px; 
    box-sizing: border-box;
    box-shadow: none;
    margin-bottom: 25px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 155px;
}
.overlayContent input[type="email"]{
    border: 1px solid #ccc;
    outline: none; 
    background-color: #ffffff;
    padding: 10px;
    box-shadow: none;
    margin-bottom: 55px;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    height: 55px;
}


@media (max-width: 768px) and (min-width: 344px) {
    .container {
        width: 100vw;
        align-content:center;
        display: flex;
        flex-direction: column;
    }
    .upload_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #ffffff;
        width: 600px;
        max-width: 90%;
        position: relative;
        padding-top: 100px;
        padding-bottom: 55px;
        border-radius: 0.43em;
        z-index: 2;
        margin: auto; 
        margin-bottom: 1vh;
        overflow-y: auto; 
        max-height: 90vh; 
    }
     
    label {
        width: 12em;
        padding: 1.12em 0;
    }
}


.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.bannerContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
}

.bannerContent h2 {
    margin-bottom: 20px;
}

.bannerContent textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    margin-bottom: 20px;
}
.bannerContent input[type="email"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px; 
}

.bannerContent button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}


.bannerContent button:hover {
    background-color: #0056b3;
}

.bannerContent button:focus {
    outline: none;
}
