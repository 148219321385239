.dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    -webkit-animation-name: dropdown-animate;
    animation-name: dropdown-animate;
    -webkit-animation-duration: 0.2s;
    animation-duration:0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82,63,105, 0.2);
}

.dropdown-menu .dropdown-header, .dropdown-menu .dropdown-footer{
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a{
    color: #444444;
    text-decoration: uderline;
}

.dropdown-menu .dropdown-footer a:hover{
    text-decoration: none;
}

.dropdown-menu .dropdown-footer a:hover{
    text-decoration: none;
}

.dropdown-menu .dropdown-divider{
    color: #a5c5fe;
    margin: 0;
}

.dropdown-menu .drop-down-item{
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.dropdown-menu .dropdown-item i{
    margin-right: 10px;
    font-size: 18px;
    line-height: o;
}

.dropdown-menu .dropdown-item:hover{
    background-color: #f6f9ff;
}

@media (min-width: 768px){
    .dropdown-menu-arrow::before{
        content: '';
        width: 13px;
        height: 13px;
        background: #fff;
        position: absolute;
        top:-7px;
        right: 20px;
        transform: rotate(45deg);
        border-top: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;
    }
}

@-webkit-keyframes dropdown-animate{
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dropdown-animate {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    0%{
        opacity: 0;
    }
}