.pagetitle {
    display: flex;
    align-items: center;
  }
  
  .breadcrumb.custom-breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .breadcrumb.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: none; /* Remove the default "/" separator */
  }
  
  .breadcrumb-item.active.pagetitle-item {
    display: flex;
    align-items: center;
  }
  
  .breadcrumb-item.active.pagetitle-item i {
    margin-right: 8px; /* Adjust spacing as needed */
  }
  
  .breadcrumb-item.active.pagetitle-item h1 {
    margin: 0;
    font-size: 1.5rem; /* Adjust font size as needed */
  }
  