.block-user {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 1000;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-content {
    background: white;
    padding: 10px;
    border-radius: 5px;
    width: 650px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    max-height: 70vh; 
    margin-top:-55px;
  }
  

  .block_details{
    padding: 10px;
  }
  
  .toggle-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .toggle-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    color: white;
  }
  
  .toggle-button.active {
    background: #5d1073; /* Purple color for active button */
  }
  
  .toggle-button.inactive {
    background: grey; /* Grey color for inactive button */
    cursor: not-allowed;
  }
  
  .toggle-button:disabled {
    background: lightgray;
    cursor: not-allowed;
  }
  
  .search-user {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center; /* Center vertically within its parent */
  align-items: center; /* Center horizontally within its parent */
  width: 100%; /* Ensure it takes full width of parent container */
  }
  
  .search-user input {
    width: 90%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    
  }
  
  .user-status {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-status p {
    margin: 0;
  }
  /* Add these styles */
.user-status p.active-status {
  color: rgb(133, 197, 133);

}

.user-status p.blocked-status {
  color: rgb(229, 111, 111);

}

  
  .user-status select,
  .user-status textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    
  }
  
  .user-status button {
    padding: 10px;
    background: #5d1073;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

.block_details {
  display: flex;
  justify-content: center; /* Center the details container horizontally */
  width: 100%; /* Ensure it takes full width of the parent container */
  padding: 10px;
  text-align: center; /* Center text inside the container */
}
  